/* Blog Page */
.blog-container {
    max-width: 1200px;
    margin: auto;
    padding: 50px 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Blog Hero */
.blog-hero {
    background: linear-gradient(135deg, #1C182F 0%, #239ce8 100%);
    color: white;
    text-align: center;
    padding: 60px 20px;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
}

.blog-hero h1 {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 10px;
}

.blog-hero p {
    font-size: 1.2em;
    opacity: 0.9;
}

/* Blog Categories */
.blog-categories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 30px;
}

.blog-categories button {
    background: #239ce8;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.blog-categories button:hover {
    background: #1c70b8;
}

/* Blog Grid Layout */
.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
}

/* Blog Cards */
.blog-card {
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    text-align: center;
    overflow: hidden;
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-card img {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    object-fit: cover;
}

/* Blog Content Area */
.blog-content {
    flex: 1;
    padding: 30px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-wrap: break-word;
}

/* Blog Sidebar Fix */
.blog-sidebar {
    width: 300px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    height: fit-content;
    margin: auto;
}

/* Responsive Fixes */
@media screen and (max-width: 1024px) {
    .blog-grid {
        grid-template-columns: repeat(2, 1fr);
        padding: 20px;
    }

    .blog-sidebar {
        width: 100%;
        position: relative;
        top: auto;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 768px) {
    .blog-hero {
        padding: 40px 15px;
    }

    .blog-grid {
        grid-template-columns: 1fr;
        padding: 15px;
    }

    .blog-content pre {
        font-size: 0.9em;
    }
}
