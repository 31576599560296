/* Research Page - Ultra Modern Look */
.research-container {
  max-width: 1200px;
  margin: auto;
  padding: 50px 20px;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

/* Hero Section */
.research-hero {
  background: linear-gradient(135deg, #1C182F 0%, #239ce8 100%);
  color: white;
  text-align: center;
  padding: 80px 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.research-hero h1 {
  font-size: 3em;
  margin-bottom: 10px;
  font-weight: bold;
}

.research-hero p {
  font-size: 1.4em;
  opacity: 0.9;
}

/* Research Grid Section */
.research-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 30px 10%;
}

/* Research Card */
.research-card {
  background: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  animation: fadeInUp 0.5s ease-in-out;
}

.research-card:hover {
  transform: translateY(-10px);
}

.research-card h3 {
  color: #239ce8;
}

.research-card p {
  font-size: 1.1em;
  line-height: 1.8;
}

/* Animated fade-in effect */
@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Footer Section */
.research-footer {
  text-align: center;
  background: #1C182F;
  color: white;
  padding: 20px;
  margin-top: 40px;
  border-radius: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .research-hero h1 {
      font-size: 2.5em;
  }
  .research-hero p {
      font-size: 1.2em;
  }
  .research-grid {
      grid-template-columns: 1fr;
      padding: 20px;
  }
}
