/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;  /* ✅ Prevents horizontal scrolling */
  font-family: 'Poppins', sans-serif;
  background-color: #f4f7fa;
  color: #333;
}

/* Prevents content from overflowing */
.home {
  max-width: 100%;
  overflow-x: hidden;
}

/* Hero Section */
.hero {
  background: linear-gradient(135deg, #1C182F 0%, #239ce8 100%);
  color: white;
  text-align: center;
  padding: 100px 5%;
  border-radius: 0 0 50px 50px;
  width: 100%; /* ✅ Ensures it fits within the screen */
  box-sizing: border-box;
}

/* Features Section */
.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  padding: 50px 10%;
  width: 100%;  /* ✅ Prevents horizontal overflow */
  box-sizing: border-box;
}

/* Feature Boxes */
.feature-box {
  background: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  max-width: 100%;
}

/* Innovation Section */
.innovation {
  text-align: center;
  padding: 60px 10%;
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
}

/* Footer */
.footer {
  background: #1C182F;
  color: white;
  text-align: center;
  padding: 20px;
  margin-top: 50px;
  width: 100%;
  box-sizing: border-box;
}

/* 🔹 Fix for Mobile Devices */
@media (max-width: 768px) {
  .hero {
      padding: 60px 5%;
      border-radius: 0 0 30px 30px;
  }
  .hero h1 {
      font-size: 2.2em;
  }
  .hero p {
      font-size: 1.1em;
  }
  .features {
      grid-template-columns: 1fr;
      padding: 30px;
  }
  .cta-button {
      font-size: 1em;
      padding: 10px 20px;
  }
  .innovation h2 {
      font-size: 2em;
  }
  .innovation p {
      font-size: 1.1em;
  }
}
