/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1C182F;
  padding: 15px 20px;
  flex-wrap: wrap;
}

.navbar ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar li {
  padding: 10px 15px;
}

.navbar li a {
  color: white;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s ease-in-out;
}

.navbar li a:hover {
  color: #ff6600;
}

/* Fix Full-Screen View Issue */
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .navbar {
      flex-direction: column;
      align-items: center;
  }

  .navbar ul {
      flex-direction: column;
      width: 100%;
      text-align: center;
  }

  .navbar li {
      padding: 10px 0;
  }
}
