/* Contact Page Container */
.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1C182F 0%, #239ce8 100%);
  padding: 40px 20px;
  width: 100%;
  box-sizing: border-box; /* Ensures proper width calculations */
}

/* Contact Form */
.contact-form {
  max-width: 450px;
  width: 100%;
  padding: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
  margin: auto; /* Centering */
  box-sizing: border-box;
}

/* Form Fields */
.form-group {
  text-align: left;
  margin-bottom: 15px;
  width: 100%;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.form-group input,
.form-group textarea {
  width: calc(100% - 20px); /* Ensures proper width inside container */
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1em;
  transition: border-color 0.3s ease-in-out;
  box-sizing: border-box;
  margin: 0 auto; /* Centers input fields */
}

/* Fix for text alignment */
input::placeholder,
textarea::placeholder {
  text-align: left;
}

/* Textarea */
.form-group textarea {
  height: 120px;
  resize: none;
}

/* Submit Button */
button[type="submit"] {
  width: 100%;
  padding: 14px;
  font-size: 1.1em;
  border: none;
  border-radius: 6px;
  background-color: #239ce8;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #1c70b8;
}

/* Success Screen */
.success-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Error Message */
.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}

/* Fade-In Animation */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: scale(0.9);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

/* 🔹 Mobile & Tablet Fixes */
@media screen and (max-width: 768px) {
  .contact-page {
      padding: 20px;
  }

  .contact-form {
      padding: 20px;
      max-width: 90%;
  }

  .form-group input,
  .form-group textarea {
      width: 100%;
  }

  button[type="submit"] {
      font-size: 1em;
  }
}

@media screen and (max-width: 480px) {
  .contact-form {
      padding: 15px;
  }

  .contact-form h2 {
      font-size: 1.5em;
  }

  .form-group input,
  .form-group textarea {
      font-size: 0.9em;
      padding: 8px;
  }

  button[type="submit"] {
      font-size: 0.9em;
      padding: 10px;
  }
}
