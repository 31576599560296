/* About Page - Ultra Modern Look */
.about-container {
    max-width: 1200px;
    margin: auto;
    padding: 50px 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
}

/* Hero Section */
.about-hero {
    background: linear-gradient(135deg, #1C182F 0%, #239ce8 100%);
    color: white;
    text-align: center;
    padding: 70px 20px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.about-hero h1 {
    font-size: 2.8em;
    font-weight: bold;
}

.about-hero p {
    font-size: 1.3em;
    opacity: 0.9;
}

/* Content Sections */
.about-section {
    background: white;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.5s ease-in-out;
}

.about-section h2 {
    font-size: 2em;
    color: #1C182F;
    border-bottom: 3px solid #239ce8;
    padding-bottom: 8px;
    margin-bottom: 15px;
}

.about-section p {
    font-size: 1.1em;
    line-height: 1.7;
}

/* Footer Section */
.about-footer {
    text-align: center;
    background: #1C182F;
    color: white;
    padding: 20px;
    margin-top: 40px;
    border-radius: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .about-hero h1 {
        font-size: 2.3em;
    }
    .about-hero p {
        font-size: 1.1em;
    }
    .about-section {
        padding: 20px;
    }
    .about-section h2 {
        font-size: 1.8em;
    }
    .about-section p {
        font-size: 1em;
        line-height: 1.6;
    }
}
